/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: "ITCAvantGardeBold";
  src: url('../webfonts/ITCAvantGardeBold/font.woff2') format('woff2'), url('../webfonts/ITCAvantGardeBold/font.woff') format('woff');
}

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .red-foam {
    background-image: url('../../src/images/redfoam.svg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 200px;

    @apply lg:bg-auto
  }

  .dripping-foam {
    background-image: url('../../src/images/drippingfoam.svg');
    background-repeat: no-repeat;
    background-position: 70% 0%;
    background-size: 0px;

    @apply lg:bg-auto
  }

  .blue-foam {
    background-image: url('../../src/images/bluefoamflip.png'), url('../../src/images/bluefoam.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: 100% 100%, 0 0;
    background-size: 0, 0;

    @apply lg:bg-auto;
  }
}
